import React from "react";

import styles from "./intro.scss";

export default ({ image, headline, children, noBottomPadding }) => (
  <section className={`intro ${noBottomPadding ? "no-bottom-padding" : ""}`}>
    <div
      className="intro--image cover"
      style={{ backgroundImage: `url(${image})` }}
    />
    <div className="intro--content">
      <h1
        className="hyphenate"
        dangerouslySetInnerHTML={{ __html: headline }}
      />
      <div className="abstract text-content">{children}</div>
    </div>
  </section>
);
