import React from "react";
import { Link } from "gatsby";

export default ({ url, type, children, ...props }) => {
  if (type === "external") {
    return (
      <a href={url} target="_blank" rel="noreferrer noopener" {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={url} {...props}>
      {children}
    </Link>
  );
};
