import React from "react";
import config from "../../data/siteConfig";
import Link from "./link";

import fbIcon from "../images/fb.svg";
import igIcon from "../images/ig.svg";
import fbIconInverted from "../images/fb-white.svg";
import igIconInverted from "../images/ig-white.svg";

import styles from "./social-link.scss";

const SocialLink = ({ link, text, className, icon }) => (
  <Link
    type="external"
    url={link}
    className={className}
    style={{ backgroundImage: `url(${icon})` }}
  >
    <span>{text}</span>
  </Link>
);

export const FacebookLink = ({ link, inverted, text }) => (
  <SocialLink
    link={link || config.socialLinks.facebook}
    text={text || "follow us on facebook"}
    icon={inverted ? fbIconInverted : fbIcon}
    className="fb"
  />
);

export const InstagramLink = ({ link, inverted, text }) => (
  <SocialLink
    link={link || config.socialLinks.instagram}
    text={text || "follow us on instagram"}
    icon={inverted ? igIconInverted : igIcon}
    className="ig"
  />
);
