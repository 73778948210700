import React from "react";
import Link from "./link";
import { FacebookLink, InstagramLink } from "./social-link";
import { footerNavigationLinks } from "../../data/siteConfig";

import "./footer.scss";

const Navigation = ({ items }) => (
  <ul className="footer-nav">
    {items.map((item, i) => (
      <li key={i}>
        <Link url={item.url}>{item.label}</Link>
      </li>
    ))}
  </ul>
);

export default () => (
  <footer>
    <div className="container">
      <div className="socialize">
        <h5>Let's socialize:</h5>
        <FacebookLink inverted={true} />
        <InstagramLink inverted={true} />
      </div>
      <div className="right">
        <Link url="/" className="logo">
          <img src={require("../images/logo-white.svg")} alt="" />
        </Link>

        <Navigation items={footerNavigationLinks} />
      </div>
    </div>
  </footer>
);
