module.exports = {
  siteTitle: "o'mellis Premium Juices & Smoothies",
  siteTitleTemplate: "%s | Omellis",
  siteDescription:
    "Mit unseren köstlichen Premium Juices und Smoothies beweisen wir, dass Gesundheit und Genuss perfekt zusammenpassen. Und das seit 1983. Überzeug auch du dich!",
  mainNavigationLinks: [
    {
      label: "Über uns",
      url: "/ueber-uns/"
    },
    {
      label: "Smoothies & Snacks",
      url: "/smoothies-snacks/"
    },
    {
      label: "Standorte",
      url: "/standorte/"
    },
    {
      label: "Impressum",
      url: "/impressum/"
    }
  ],
  footerNavigationLinks: [
    {
      label: "Datenschutz",
      url: "/datenschutz/"
    },
    {
      label: "Impressum",
      url: "/impressum/"
    }
  ],
  socialLinks: {
    instagram: "https://www.instagram.com/_omellis/",
    facebook: "https://www.facebook.com/omellis.smoothiebar"
  }
};
