import React from "react";
import Link from "./link";
import { FacebookLink, InstagramLink } from "./social-link";
import { mainNavigationLinks } from "../../data/siteConfig";

import styles from "./header.scss";

const toggleMenu = () => {
  document.body.classList.toggle("nav-open");
};

const HamburgerButton = () => (
  <button type="button" className="hamburger" onClick={toggleMenu}>
    <span className="f">
      <i />
      <i />
      <i />
    </span>
    <span className="x">
      <i />
      <i />
    </span>
  </button>
);

const HeaderBar = () => (
  <div className="header-bar">
    <Link url="/" className="brand">
      <img src={require("../images/logo.svg")} alt="" />
      <span>Omellis</span>
    </Link>
    <HamburgerButton />
  </div>
);

const NavigationItem = ({ to, label }) => (
  <li className="">
    <div className="trw">
      <Link url={to} onClick={toggleMenu} activeClassName="active">
        {label}
      </Link>
    </div>
  </li>
);

const Navigation = () => (
  <div className="nav-container">
    <div className="nav-wrapper">
      <div className="scroll-container">
        <nav>
          <ul>
            {mainNavigationLinks.map((item, i) => (
              <NavigationItem to={item.url} label={item.label} key={i} />
            ))}
          </ul>
        </nav>
        <div className="socialize">
          <FacebookLink />
          <InstagramLink />
        </div>
      </div>
    </div>
  </div>
);

export default () => (
  <header>
    <HeaderBar />
    <Navigation />
  </header>
);
